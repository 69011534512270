import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

export default {

  user: {},
  error: {},

  /* START OF BASE METHODS */

  async setHeader() {
    // Set axios token          
    await this.user.getIdToken().then((token) => axios.defaults.headers.common['Authorization'] = 'Bearer ' + token)
  },
  handleRequest(request, resource) {
    return request()
      .then((r) => r)
      .catch(() => {
        this.error.visible = true;
        this.error.text = 'Ett fel inträffade - var god försök igen. Kontakta supporten och ange felkod #' + this.toNumbers(resource) + ' om felet kvarstår.';
        throw Error('');
      })
  },
  get(resource, params, config = {}) {
    return this.setHeader().then(() => this.handleRequest(() => axios.get(resource, { params: params, ...config }), resource))
  },
  post(resource, data, config = {}) {
    return this.setHeader().then(() => this.handleRequest(() => axios.post(resource, data, config), resource))
  },
  put(resource, data) {
    return this.setHeader().then(() => this.put(() => axios.put(resource, data), resource))
  },
  delete(resource, data) {
    return this.setHeader().then(() => this.put(() => axios.delete(resource, data), resource))
  },

  toNumbers(s) {
    function zeroPad(n, w) {
      while (n.toString().length < w) n = '0' + n;
      return n;
    }

    var base64 = Buffer.from(s).toString('base64')
    var nums = '';
    for (var i = 0; i < s.length; i++) {
      nums += zeroPad(base64.charCodeAt(i), 3);
    }
    return nums.slice(0, 5);
  },

  /* END OF BASE METHODS */

  /* START OF API CALLS */

  async createSwishRequest(amount) {
    return this.post('swish/request', { amount: amount }).then(resp => resp.data);
  },

  async checkSwishStatus(transactionId) {
    return this.post('swish/status', { transactionId: transactionId }).then(resp => resp.data);
  },

  async createPayPalOrder(amount) {
    return this.post('paypal/order', { amount: amount }).then(resp => resp.data);
  },

  async capturePayPalOrder(orderId) {
    return this.post('paypal/order/capture', { orderId: orderId }).then(resp => resp.data);
  },

  async sendAICommand(textType, command, streamId, values) {
    return this.get(
      'ai/command',
      { textType: textType, command, streamId, parameters: values },
    ).then(resp => {
      return resp.data
    });
  },

  async setReferral(referral) {
    return this.post('user/referral', { referral: referral }).then(resp => resp.data);
  },

  async contact(message) {
    return this.post('user/message', { message: message }).then(resp => resp.data);
  },

  async setAvatar(avatarId) {
    return this.post('user/avatar', { avatarId: avatarId }).then(resp => resp.data);
  },

  async setUserConsent(consent) {
    return this.post('user/consent', { consent: consent }).then(resp => resp.data)
  },

  async deleteUser() {
    return this.delete('user/delete', {}).then(resp => resp.data)
  },

  async sendSMS(phoneNumber, recaptchaToken) {
    return this.post('user/sendSMS', { phoneNumber: phoneNumber, recaptchaToken: recaptchaToken }).then(resp => resp.data)
  },

  async verifySMS(smsCode) {
    return this.post('user/verifySMS', { smsCode: smsCode }).then(resp => resp.data)
  },

  async updateUserInfo(info) {
    return this.post('user/update', { info }).then(resp => resp.data)
  },

  async getIsUserSetup() {
    return this.post('user/isSetup', {}).then(resp => resp.data)
  },

  async dismissAlert(alertName) {
    return this.post('user/dismissAlert', { alertName: alertName }).then(resp => resp.data)
  },

  async setRefreshToken(refreshToken) {
    return this.post('user/setRefreshToken', { refreshToken }).then(resp => resp.data)
  },

  async createDocument(buffer, title, docsId) {
    return this.post('google/createDocument', { buffer, title, fileId: docsId ?? '' }).then(resp => resp.data)
  }


  /* END OF API CALLS */


}
