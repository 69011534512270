import { defineStore } from 'pinia'

let db
const setDB = () => import('../databases/firestore').then((i) => db = i.default)

export const useSystemStore = defineStore('system', {
  state: () => {
    return {
      status: {},
      templates: {
        text: []
      },
      initialized: false,
      appReady: true,
      authAction: '',
      innerWidth: document.innerWidth,
      innerHeight: document.innerHeight,
      icons: [],
      altLoginBtnText: null,
      error: {
        visible: false,
        text: ''
      }
    }
  },
  actions: {

    initialize(fs, userId) {
      const store = this

      this.innerWidth = window.innerWidth
      this.innerHeight = window.innerHeight
      addEventListener("resize", () => {
        this.innerWidth = window.innerWidth
        this.innerHeight = window.innerHeight
      })

      if (userId) {
        return new Promise(function (resolve) {
          setDB().then(() =>
            db.open({ fs, userId }).then((values) => {
              store.status = values[0]
              store.templates = values[1]
              store.initialized = true

              resolve()
            })
          )
        })
      }
      return
    }

  },
})