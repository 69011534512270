const loadPdfHelper = async (config, save) =>
  await import("./pdfHelper").then(
    (h) => new h.default(config, save)
  );

const loadDocxHelper = async (config) =>
  await import("./docxHelper").then(
    (h) => new h.default(config)
  );

Array.prototype.groupBy = function (count) {
  let array = []
  this.forEach((v, i) => {
    const groupIndex = Math.floor(i / count)
    if (!array[groupIndex]) array[groupIndex] = []
    array[groupIndex].push(v)
  })
  return array
}


export default Object.freeze(
  {

    mmToPt(mm) {
      return mm * 2.8346456693
    },

    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },

    removeCookie(name) {
      document.cookie = name + '=; Max-Age=0'
    },

    getCopy(obj) {
      return JSON.parse(JSON.stringify(obj))
    },

    getDocConfig(text, user) {
      return {
        settings: {
          pageFormat: "a4",
          pageSize: {
            width: this.mmToPt(210),
            height: this.mmToPt(297)
          },
          unit: "pt",
        },
        font: {
          family: "TimesNewRoman",
          type: "normal",
          sizes: {
            p: 12,
            h1: 18,
            h2: 14,
            title: 22,
            author: 14
          },
          spacingAfter: 12,
        },
        sections: {
          hasStructure: text.structure && Object.keys(text.structure).length,
          hasCoverPage: text.docOptions.includes("coverPage"),
          hasTableOfContents: text.docOptions.includes("contentsTable"),
        },
        content: {
          author: text.docOptions.includes("credits") ? user.displayName : "",
          text: text,
        },
        layout: {
          marginX: this.mmToPt(25.4),
          marginY: this.mmToPt(25.4),
          hasPageNumbers: text.docOptions.includes("pageNumbers"),
        },
      }
    },

    async downloadDocument(option, text, user, save = true, api) {
      const config = this.getDocConfig(text, user)

      switch (option) {
        case "pdf":
          return this.downloadPdf(config, save)
        case "docx":
          this.downloadDocx(config)
          break;
        case "print":
          this.printPdf(await this.downloadPdf(config, false))
          break;
        case 'docs':
          return this.createDocument(config, text, api)
      }
    },

    async downloadPdf(config, save) {
      const pdfHelper = await loadPdfHelper(config, save);
      return pdfHelper.createPdf();
    },

    printPdf(pdf) {
      let iframe = document.createElement("iframe");
      document.body.appendChild(iframe);

      iframe.style.display = "none";
      iframe.src = pdf;

      iframe.onload = async function () {
        this.contentWindow.focus()
        this.contentWindow.print()
      };
    },

    async getDocx(config) {
      const docxHelper = await loadDocxHelper(config);
      return docxHelper.createDocx(this.docConfig);
    },

    async downloadDocx(config) {
      const docxHelper = await loadDocxHelper(config);
      const buffer = await docxHelper.createDocx(this.docConfig);
      docxHelper.download(buffer);
    },

    async createDocument(config, text, api) {
      const buffer = await this.getDocx(config);
      return api.createDocument(buffer, text.title, text.docsId);
    },

    /**
    * Take the result for the AI-commands and transforms it to the wanted format
    * @param {object} commandType - An object containing command information
    * @param {string} result - Text result from the executed AI-command
    * @return {string} The formatted result
    */
    processAIResult(commandType, result) {
      if (commandType === 'text')
        return result.replace(/^\s+|\s+$/g, '').replaceAll('^"|"$', '').trim()
      else
        return result.split(/\r?\n/)
          .map((p) => p.replace(/^[0-9]+./, ''))
          .map((p) => p.replace(/['"]+/g, ''))
          .map((p) => p.replaceAll('^"|"$', ''))
          .map((p) => p.trim())
          .filter((p) => p)
    },

    restartTimeout(timeouts, key, callback, duration) {
      if (timeouts[key]) clearTimeout(timeouts[key])
      timeouts[key] = setTimeout(() => callback(), duration)
    }

  }
)