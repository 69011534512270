const guides = require('../helpers/constants').guides.map((g) => ({
  ...g,
  title: encodeURIComponent(g.title.toLowerCase())
}));

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: () => import('@/components/routes/Landing'),
    meta: {
      canoncial: '',
      pageDescription: 'Revolutionera din skrivprocess med vårt AI-baserade skrivstöd – snabbare, smartare, roligare!',
      faq: [
        {
          q: 'Hur fungerar AI-baserad textgenerering?',
          a: 'AI-baserad textskrivning är en typ av teknik som använder artificiell intelligens för att automatiskt upprätta texter baserat på ett givet ämne. Maskininlärning tar data från olika källor och upptäcker mönster som hjälper datorn att förstå ämnet. Därefter använder datorn de upptäckta mönstren för att skapa en unik text som är relevant och intressant.'
        },
        {
          q: 'Hur lång tid tar det att skriva en text med hjälp av AI?',
          a: 'Att skriva en text med en AI-baserad textskrivningstjänst tar normalt sett väldigt kort tid. Du kan oftast få ett första utkast genererat på mindre än en minut. Att skriva en text med en AI-baserad textskrivningstjänst är det snabbaste och mest effektiva sättet att ta sig från en idé till en fullständig och högkvalitativ text.'
        },
        {
          q: 'Vilka begränsningar finns det med AI-baserad textskrivning?',
          a: 'AI-genererad textskrivning har visat sig vara en kraftfull teknik, men det finns några utmaningar att överväga. Bland dessa finns begränsad kreativitet och känslomässig förståelse, svårigheter med specialiserad kunskap samt behovet av massivt datatillförsel och energiåtgång för träning. Men med medvetenhet om dessa begränsningar kan vi använda tekniken på ett ansvarsfullt och effektivt sätt för olika applikationer.'
        },
        {
          q: 'Är texter som genereras av AI unika?',
          a: 'Texter som genereras med AI kan vara unika och originella eftersom AI-modellerna lär sig från stora och varierade datamängder. Genom att kombinera och syntetisera information från olika källor kan AI producera texter som inte har exakt samma struktur, ordval eller innehåll som något befintligt verk. Dessutom kan AI skapa ovanliga kopplingar mellan olika ämnen och erbjuda nya perspektiv, vilket kan leda till texter som är fräscha och innovativa.'
        }
      ],
      load: () => Promise.resolve()
    }
  },
  {
    path: '/start',
    name: 'Start',
    component: () => import('@/components/routes/Start'),
    meta: {
      canoncial: '',
      requiresAuth: true,
      load: () => Promise.resolve()
    }
  },
  {
    path: '/text',
    name: 'Text',
    component: () => import('@/components/routes/Text'),
    meta: {
      load: () => Promise.resolve()
    }
  },
  {
    path: '/text/:textId',
    name: 'Redigera text',
    component: () => import('@/components/routes/Text'),
    meta: {
      canoncial: '/app',
      requiresAuth: true,
      load: () => Promise.resolve()
    }
  },
  {
    path: '/konto',
    name: 'Mitt konto',
    component: () => import('@/components/routes/Account'),
    meta: {
      canoncial: '/konto',
      requiresAuth: true,
      load: () => Promise.resolve()
    }
  },
  {
    path: '/bjud-in',
    name: 'Bjud in vänner',
    component: () => import('@/components/routes/Affiliate'),
    meta: {
      canoncial: '/bjud-in',
      requiresAuth: false,
      load: () => Promise.resolve()
    }
  },
  {
    path: '/kontakta-oss',
    name: 'Kontakta oss',
    component: () => import('@/components/routes/Contact'),
    meta: {
      canoncial: '/kontakta-oss',
      requiresAuth: false,
      load: () => Promise.resolve()
    }
  },
  {
    path: '/integritetspolicy',
    name: 'Integritetspolicy',
    component: () => import('@/components/routes/Integrity'),
    meta: {
      canoncial: '/integritetspolicy',
      requiresAuth: false,
      load: () => Promise.resolve()
    }
  },
  {
    path: '/villkor',
    name: 'Användarvillkor',
    component: () => import('@/components/routes/Terms'),
    meta: {
      canoncial: '/villkor',
      requiresAuth: false,
      load: () => Promise.resolve()
    }
  },
  {
    path: '/etik',
    name: 'Etiskt ställning',
    component: () => import('@/components/routes/Ethics'),
    meta: {
      canoncial: '/etik',
      requiresAuth: false,
      load: () => Promise.resolve()
    }
  },
  {
    path: '/admin',
    name: 'Administration',
    component: () => import('@/components/routes/Admin'),
    meta: {
      canoncial: '/admin',
      requiresAuth: true,
      load: () => Promise.resolve()
    }
  },
  {
    path: '/guider',
    name: 'Textguider',
    component: () => import('@/components/routes/Guides'),
    meta: {
      canoncial: '/guider',
      requiresAuth: false,
      load: () => Promise.resolve()
    }
  },
  ...guides.map((g) => ({
    path: `/guider/${decodeURIComponent(g.title)}`,
    alias: `/guider/${g.title}`,
    name: g.title,
    component: () => import('@/components/routes/Guides'),
    meta: {
      canoncial: `/guider/${g.title}`,
      requiresAuth: false,
      load: () => Promise.resolve(),
      faq: g.faq,
      title: g.metaTitle,
    }
  })),
  {
    path: '/:notFound',
    name: '404',
    component: () => import('@/components/routes/404'),
    beforeEnter() {
      if (window.location.pathname !== '/404')
        window.location.href = "/404"
    },
    meta: {
      canoncial: '/404',
      requiresAuth: false,
      load: () => Promise.resolve()
    }
  }
]

module.exports = { routes }