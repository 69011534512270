import { defineStore } from 'pinia'

let db
const setDB = () => import('../databases/firestore').then((i) => db = i.default)

export const useUserStore = defineStore('counter', {
  state: () => {
    return {
      user: null,
      fsUser: null,
      texts: null,
      logs: [],
      transactions: [],
      referrals: null,
      isSetup: true,
      refreshToken: ''
    }
  },
  actions: {

    async initialize(vm = this) {
      if (this.user) {
        return new Promise(function (resolve) {
          setDB().then(() =>
            db.getUserData().then((values) => {
              vm.texts = values[0].map(t => {
                if (!t.docOptions) t.docOptions = []
                return t
              })
              vm.fsUser = values[1]
              vm.logs = values[2]
              vm.transactions = values[3]
              vm.referrals = values[4]

              resolve(vm.fsUser)
            })
          )
        })
      }
      return
    },

    refreshBalance() {
      db.user.get().then((user) => {
        this.fsUser.credits = user.credits
      })
    },

    refreshUser() {
      db.user.get().then((user) => {
        this.fsUser = user
      })
    },

    setUser(user) {
      this.user = user
    },

    setConsent(consent) {
      this.fsUser.consent = consent
    },

    createText(text) {
      let vm = this

      return new Promise(function (resolve) {
        text = { 
          ...text, 
          userId: vm.user.uid, 
          stepId: 1, 
          maxStepId: 1, 
          level: vm.fsUser.level ?? 'Standard', 
          language: vm.fsUser.language ?? 'Svenska' }

        db.texts.put(text).then((id) => {
          text.id = id
          vm.texts.push(text)
          resolve(id)
        })
      })
    },

    updateText(text) {
      db.texts.put(text)
    },

    getTableOffset(table, orderBy, offset, limit, filters) {
      const data = JSON.parse(JSON.stringify(this[table]))
      this[table] = []

      switch (offset) {
        case 1:
          db[table].getOffset(0, 'desc', limit + 1, filters).then((rows) => {
            this[table] = rows
          })
          break;
        case 2:
          data.shift()
          db[table].getOffset(data[0][orderBy], 'asc', limit + 1, filters).then((rows) => {
            this[table] = rows.reverse()
          })
          break;
        case 3:
          data.pop()
          db[table].getOffset(data[data.length - 1][orderBy], 'desc', limit + 1, filters).then((rows) => {
            this[table] = rows
          })
          break;
        case 4:
          Promise.all([db[table].getOffset(0, 'asc', limit + 1, filters), db[table].getCount()]).then((values) => {
            this[table] = values[0].splice(0, (values[1] % limit === 0 ? limit : values[1] % limit)).reverse()
          })
          break;
      }
    },

    getTableOffset2(table, orderBy, offset) {
      let order = offset > 0 ? 'desc' : 'asc'
      let start = (offset > 0 ? this[table][this[table].length - 1] : this[table][0])[orderBy]
      let limit = Math.abs(offset) * 10

      db[table].getOffset(start, order, limit).then((rows) => {
        rows.splice(0, (limit - 10))
        if (order === 'asc')
          rows = rows.reverse()

        this[table] = rows
      })
    },

  },
})