const config = await import(`../configs/firebase/${process.env.VUE_APP_PROJECT_NAME}.json`)

const firebase = async (config) => {
  const { initializeApp } = await import("firebase/app")
  return await initializeApp(config)
}
const analytics = async (app) => {
  const { getAnalytics } = await import("firebase/analytics")
  await getAnalytics(app)
}
const firestore = async (app) => {
  const { getFirestore } = await import("firebase/firestore")
  const db = await getFirestore(app)
  return db
}
const auth = async (app) => {
  const { getAuth } = await import("firebase/auth")
  return await getAuth(app)
}
/*const getUnlink = async () => {
  const { unlink } = await import("firebase/auth")
  return unlink
}*/
/*const getFetch = async () => {
  const { fetchSignInMethodsForEmail } = await import("firebase/auth")
  return fetchSignInMethodsForEmail
}*/
const onAuthStateChanged = async (auth, fn) => {
  const { onAuthStateChanged } = await import("firebase/auth")
  return onAuthStateChanged(auth, fn)
}

const loadAPI = async () => import('../api/Functions').then(API => API.default)

export default {
  data() {
    return {
      userToken: '',
      fsUser: null,
      config
    }
  },
  methods: {
    async init() {
      // Init Firebase
      return await firebase(this.config)
    },
    async authenticate(app) {
      var vm = this

      // Init auth
      const authentication = await auth(vm.$fb)

      onAuthStateChanged(authentication, async (user) => {
        vm.$systemStore.appReady = false

        if (user) {
          // Load firestore
          if (!vm.$firestore)
            app.config.globalProperties.$firestore = await firestore(vm.$fb)

          // Set current auth state 
          vm.$systemStore.authAction = 'signIn'

          // Update API-config
          loadAPI().then((API) => API.user = user)

          // Wait for account setup
          if (!vm.$userStore.isSetup) {
            await new Promise(resolve => {
              const interval = setInterval(async () => {
                if (await vm.$API.getIsUserSetup()) {
                  resolve()
                  clearInterval(interval)
                  vm.$userStore.isSetup = true
                }
              }, 1000);
            })
          }

          // Open FS and init system store
          await vm.$systemStore.initialize(vm.$firestore, user.uid)

          // Redirect if still on landing page
          if (vm.$route.matched[0].name === "Landing")
            this.$router.push("/start")

          // Get/set user data
          vm.$userStore.setUser(user)
          this.fsUser = await vm.$userStore.initialize()

          // Init text store
          await vm.$textStore.initialize()          
        } else {
          // Signed out
          this.$userStore.setUser(null)

          // Redirect to landing page upon signing out
          if (this.$systemStore.authAction === 'signOut') {
            this.$router.push("/")
          }

          // Open FS and init system store
          await vm.$systemStore.initialize(vm.$firestore)
        }

        // Check if current route requires authentication
        await vm.$router.onAuth(user)

        // Set app ready
        vm.$systemStore.appReady = true

        // Activate Analytis
        if (this.getHasAnalyticsConsent(await this.fsUser))
          analytics(vm.$fb)
      });

      return authentication
    },
    authenticateGoogle() {
      this.$firebase.auth().signInWithPopup(new this.$firebase.auth.GoogleAuthProvider())
    },
    getHasAnalyticsConsent(user) {
      const cookie = JSON.parse(this.$helpers.getCookie('consent') ?? '{}')
      return (user && user.consent !== undefined && user.consent.cookies.analytics)
        || (cookie.accepted === undefined || ((!user || user.consent === undefined) && cookie.accepted && cookie.cookies.analytics))
    }
  }
}
