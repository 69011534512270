import { createApp, defineAsyncComponent, nextTick, reactive } from 'vue/dist/vue.esm-bundler.js'
import FirebaseSetup from './mixins/FirebaseSetup'

import "bootstrap/dist/css/bootstrap.min.css"

const loadStores = () => {
  const { useSystemStore } = require('./stores/system')
  const { useUserStore } = require('./stores/user')
  const { useTextStore } = require('./stores/text')

  return {
    system: useSystemStore,
    user: useUserStore,
    text: useTextStore
  }
};

const app = createApp({ // eslint-disable
  components: {
    App: defineAsyncComponent(() => import('./App.vue')),
  },
  template: '<App/>',
  mixins: [
    FirebaseSetup
  ],
  computed: {
    route() {
      return this.$route.matched.length ? this.$route.matched[0] : {}
    }
  },
  methods: {

    async loadFirebase() {
      const dbExists = (await window.indexedDB.databases())
        .some(db => db.name === 'firebaseLocalStorageDb')

      if (!this.firebaseInitStarted) {
        this.firebaseInitStarted = true

        this.init().then((fb) => {
          // Set global firebase variables
          app.config.globalProperties.$fb = fb

          const authenticate = (vm) => {
            this.$systemStore.appReady = false
            vm.authenticate(app).then((auth) => app.config.globalProperties.$auth = auth)
          }

          // Only load auth if necessary
          if (dbExists) authenticate(this)
          else app.config.globalProperties.$loadAuth = async () => await authenticate(this)
        })

      }
    },

    autoGrow(addHeight) {
      nextTick().then(() => {
        document.querySelectorAll('.auto-grow').forEach((elem) => {
          elem.style.height = '5px'
          elem.style.height = (elem.scrollHeight + 7) + (addHeight ?? 0) + 'px'
        })
      })
    }

  },
  async created() {
    const stores = loadStores()
    app.config.globalProperties.$systemStore = stores.system()
    app.config.globalProperties.$userStore = stores.user()
    app.config.globalProperties.$textStore = stores.text()
    app.config.globalProperties.$textStore.userStore = this.$userStore

    // Give API access to error info box
    this.$API.error = this.$systemStore.error

    // Unready first load if auth is required
    router.beforeResolve(async (to) => {
      if (to.meta.requiresAuth && !this.$systemStore.initialized) this.$systemStore.appReady = false   
    })

    app.config.globalProperties.$window = reactive({
      scroll: window.scrollY,
      height: window.innerHeight,
      width: window.innerWidth
    });

    // Add listeners
    const events = ['scroll', 'resize']
    events.forEach(evt =>
      addEventListener(evt, () => {
        const { $window } = app.config.globalProperties
        $window.scroll = window.scrollY
        $window.height = window.innerHeight
        $window.width = window.innerWidth
      }, false)
    )
  },
  async mounted() {
    // Add autoGrow as a global method
    app.config.globalProperties.$autoGrow = this.autoGrow

    // Load firebase
    await this.loadFirebase()
  },
  watch: {
    route(route) {
      if (route.meta && route.meta.requiresAuth)
        this.$systemStore.authAction = 'signIn'
    },
  }
})

import { OhVueIcon, addIcons } from "oh-vue-icons";

// Global components
app.component('ConLink', defineAsyncComponent(() => import('@/components/elements/ConditionalLink.vue')))
app.component('v-async-icon', defineAsyncComponent(() => import('@/components/elements/AsyncIcon.vue')))
app.component('v-icon', OhVueIcon);

app.config.globalProperties.$addIcons = (icons) => {
  const availableIcons = app.config.globalProperties.$systemStore.icons
  icons
    .filter((i) => !availableIcons.includes(i.name))
    .forEach((i) => {
      addIcons(i)
      availableIcons.push(i.name)
    })
}

import VueLazyLoad from 'vue3-lazyload'
app.use(VueLazyLoad)

import router from './router'
app.use(router)

import { createPinia } from 'pinia'
app.use(createPinia())

import constants from './helpers/constants'
app.config.globalProperties.$const = constants

import helpers from './helpers/helpers'
app.config.globalProperties.$helpers = helpers

import API from './api/Functions'
app.config.globalProperties.$API = API

app.mount('#app')

// Plugins/components that can be loaded after mount

import('./assets/fonts/roboto-slab.css')
import('@formkit/auto-animate/vue').then(({ autoAnimatePlugin }) => {
  app.use(autoAnimatePlugin)
})