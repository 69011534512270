import { defineStore } from 'pinia'
import { deleteField } from "firebase/firestore";

import API from '../api/Functions'
import constants from '../helpers/constants'
import helpers from '../helpers/helpers'


let set, unset
const setLodash = () => import('lodash').then((lodash) => {
  set = lodash.default.set
  unset = lodash.default.unset
})

let db
const setDB = () => import('../databases/firestore').then((i) => db = i.default)

const defaultParams = [
  'language', 'level', 'target', 'type'
];

export const languages = constants.languages
  .map((l) => l[0].toUpperCase() + l.slice(1))
  .sort((a, b) => ['Svenska', 'Engelska'].includes(a) ? -1 : ['Svenska', 'Engelska'].includes(b) ? 1 : a.localeCompare(b));

const settingsElements = [
  [
    {
      type: "input",
      prop: "type",
      icon: "FaList",
      name: "Texttyp",
      disabled: true,
    },
    {
      type: "select",
      id: "language",
      prop: "language",
      icon: "FaLanguage",
      name: "Språk",
      values: languages,
      shepherd: {
        title: "Språkval",
        text: "I den första grundinställningen ställer du in vilket språk som texten ska skrivas på.",
      },
    }
  ],
  [
    {
      type: "select",
      id: "level",
      prop: "level",
      icon: "FaUserGraduate",
      name: "Språklig nivå",
      values: ["Lättare", "Standard", "Avancerad"],
      shepherd: {
        title: "Språklig nivå",
        text: "Välj nu vilken språklig nivå som texten ska vara skriven på.",
      },
    },
    {
      type: "input",
      id: "target",
      prop: "target",
      icon: "FaUsers",
      name: "Målgrupp",
      requiredFields: [],
      shepherd: {
        title: "Målgrupp",
        text: "Ange vem målgruppen är. Är det exv allmänheten, akademiker, yrkesverksamma, tonåringar eller någon annan specifik grupp?",
      },
    }
  ],
];

export const useTextStore = defineStore('text', {
  state: () => {
    return {
      text: constants.defaultText,
      loaded: false,
      tour: null,
      template: {},
      elements: [],
      settings: {
      },
      specification: {
        elem: {}
      },
      improvement: {
        topic: {},
        keywordIdeas: [],
        result: ''
      },
      modals: {
        generate: {
          visible: false
        },
        structure: {
          visible: false
        },
        improvement: {
          visible: false
        }
      },
      buttons: [],
      list: {},
      download: [],
      userStore: null,
      stepCompleted: false,
      timeouts: {},
      result: '',
      isRunningCommand: false
    }
  },
  actions: {

    async initialize() {
      await setLodash()
      await setDB()
    },

    nextStep() {
      let { id, stepId, maxStepId } = this.text

      stepId++
      if (stepId > maxStepId) maxStepId++
      this.updateText({ id, stepId, maxStepId })
    },

    prevStep() {
      this.text.stepId -= 2;
      this.nextStep();
    },

    async streamResult(command, reader) {
      let active = true
      let index = 0

      while (active) {
        const result = this.result

        if (result.length > index) {
          reader(
            helpers.processAIResult(command.resultType, result.slice(0, index++))
          )
        }

        if (index && index === (result.indexOf('[DONE]') + 1)) {
          active = false
          this.result = ''
        } else {
          // Sleep
          await new Promise(resolve => setTimeout(resolve, 10))
        }
      }
    },

    async textCommand(data, reader, streamId = 0) {
      let timeout = null
      const isNewCommand = streamId === 0      
      streamId = isNewCommand ? Date.now() : streamId      
      const command = await db.textCommands.get(this.text.type, data.commandName)
      const unsub = await db.texts.openStream(this.text.id, (doc) => {
        if (!this.result.includes('[DONE]')) {
          this.result = (doc.data().streams?.[streamId]?.result ?? []).join('')
        } else {
          // Remove stream once result has been saved
          setTimeout(() => (
            this.text.streams[streamId] = deleteField()
          ), 5000)          
        }

        if (this.result.includes('[ERROR]')) {
          clearTimeout(timeout);
          timeout = setTimeout(() => this.isRunningCommand = false, 5000)
        }
      })

      this.isRunningCommand = true

      if (isNewCommand) {
        // Process command
        API.sendAICommand(
          this.text.type,
          command,
          streamId,
          {
            id: this.text.id,
            ...Object.assign({},
              ...[...command.parameters, ...defaultParams].map(f => ({ [f]: data[f] })))
          }
        ).then((text) => {
          unsub()
          this.result = text + '[DONE]'
          this.isRunningCommand = false
          setTimeout(() => {
            this.userStore.refreshBalance()
            this.refreshTextCredits()
          }, 1000)
        }).catch(() => {
          unsub()
          timeout = setTimeout(() => this.isRunningCommand = false, 5000)
        })
      }

      // Start stream observer
      await this.streamResult(command, reader)
    },

    async generateStructure(streamId) {
      if (streamId || !Object.keys(this.text.structure).length || confirm('Vill du generera om och ersätta befintlig innehållsstruktur?')) {
        this.textCommand(
          { commandName: 'generateBullets', ...this.text },
          (result) => {
            const bullets = result
              .filter(b =>
                !b.endsWith(':')
              )
              .map((b) => ({
                title: b.trim().replace(/(^[*\s]+)|([*\s]+$)/, ''),
                level: ['***', '**', '*'].find((n) => b.trim().startsWith(n)).length
              }))
  
            const getChildren = (level, index) => {
              const children = {};
  
              bullets.slice(index).every((c, i) => {
                if (c.level === level)
                  children[Object.keys(children).length] = {
                    ...c,
                    structure: getChildren(level + 1, index + i + 1),
                  };
                return c.level >= level;
              });
  
              // Limit to two levels for now
              return level <= 2 ? children : [];
            };
  
            this.text.structure = getChildren(1, 0)
            this.updateText({
              id: this.text.id,
              structure: this.text.structure
            })
          },
          streamId
        )
      }
    },

    generateBulletText({ bullet, key, callback }, streamId) {
      const text = this.text;
      const streamKey = streamId ? text.streams[streamId]?.key : ''

      const getStructure = (bullets, level) => {
        bullets = Object.values(bullets)
          .sort((a, b) => a.order - b.order);

        return bullets.map((b, i) =>
          [
            `${level}${i + 1}. ${b.title}`,
            ...(b.structure ? getStructure(b.structure, `${level}${i + 1}.`) : [])
          ].flat())
      };

      const bullets = getStructure(text.structure, '')
        .flat()
        .join(',')

      return new Promise((resolve) => (
        this.textCommand(
          {
            commandName: 'generateContent',
            ...{ ...text, bulletKey: key, bullets }
          },
          (result) => {
            result = result.replace(`${bullet.title}\n\n`, '').replace(`${bullet.title}\n`, '')

            if (key) this.updateText({ id: text.id, [key]: result }, !this.isRunningCommand)
            if (callback) callback(result)
          },
          streamKey ? streamId : 0
        ).then(() => resolve())
      ));
    },

    async generateContent(resume = false, streamId = 0) {
      const vm = this
      const text = this.text
      const steps = this.template.steps
      const hasStructure = steps.some(s => s.name === 'structure')

      const confirmation = resume || hasStructure
        ? !Object.values(text.structure ?? {}).some(s => s.text) || confirm('Vill du generera om och därmed ersätta samtliga befintliga texter?')
        : !text.text || confirm('Vill du generera om och därmed ersätta befintlig text?')

      if (hasStructure && confirmation) {
        // Reset
        if (!resume) {
          (function loopBullets(bullets) {
            Object.values(bullets).forEach((bullet) => {
              bullet.text = ''
              loopBullets(bullet.structure)
            })
          })(text.structure, 'structure');
        }

        // Generate
        (function loopBullets(bullets, key) {
          return Object.entries(bullets).reduce((promise, [i, bullet]) => (
            promise.then(() =>
              [
                Object.keys(bullet.structure).length
                  ? () => loopBullets(bullet.structure, `${key}.${i}.structure`)
                  : () => vm.generateBulletText({ bullet, key: `${key}.${i}.text` }, streamId)
              ].reduce((p, fn) => p.then(fn), Promise.resolve())
            )
          ), Promise.resolve())
        })(text.structure, 'structure');
      } else if (confirmation) {
        text.text = ''

        this.textCommand(
          {
            commandName: 'generateContent',
            ...text,
            bullets: []
          },
          (result) => this.updateText({
            id: text.id,
            text: result.replace(`${text.title}\n\n`, '')
          }),
          streamId
        )
      }
    },

    setDefaultText() {
      this.text = constants.defaultText
    },

    // Reset current/max step (if needed)
    checkStepProgress(text, template) {
      const stepCount = template.steps.length + 1

      if (text.stepId > stepCount)
        text.stepId = stepCount
      else if (text.stepId <= 0)
        text.stepId = 1

      if (text.maxStepId >= stepCount)
        text.maxStepId = stepCount
      else if (text.maxStepId <= 0)
        text.maxStepId = 1
    },

    loadText(textId) {
      const store = this

      return new Promise(function (resolve) {
        db.texts.get(textId).then((t) => {
          if (!t) resolve(false)

          // Set text
          store.text = t

          // Get template
          db.textTemplates
            .get(t.type)
            .then((template) => {
              store.checkStepProgress(t, template)
              store.template = template

              // Load spec elements
              db.textElements
                .getArray(t.type)
                .then((elements) => {
                  store.elements = [
                    ...elements,
                    ...settingsElements.flat(),
                  ];

                  store.loaded = true
                  resolve(true)
                })

              // Resume uncompleted streams
              Object.keys(t.streams ?? [])
                .sort((a, b) => parseInt(b) - parseInt(a))
                .every((streamId, i) => {
                  const stream = t.streams[streamId]

                  if (i === 0 && confirm('Du har ett kommando som inte är färdigkört. Vill du återuppta kommandot?')) {
                    switch (stream.commandName) {
                      case 'generateContent':
                        t.stepId = template.steps.some((t) => t.name === 'structure') ? 4 : 3
                        store.generateContent(true, streamId)
                        break;
                      case 'generateStructure':
                        t.stepId = 3
                        store.generateStructure(streamId)
                        break;
                    }
                  } else {
                    // Remove stream
                    store.text.streams[streamId] = deleteField()
                  }
                })
            })
        })
      })
    },

    refreshTextCredits() {
      db.texts.get(this.text.id).then((t) => this.text.credits = t.credits)
    },

    updateText(text, forceSave) {
      // Update DB
      helpers.restartTimeout(
        this.timeouts,
        Object.keys(text).join(''),
        () => db.texts.put({
          ...text,
          lastEditDate: Date.now()
        }),
        forceSave ? 0 : 3000
      )

      // Update locally
      Object.entries(text).forEach(([key, value]) => {
        const method = value._methodName

        if (method) {
          switch (method) {
            case 'deleteField':
              unset(this.text, key)
              break;
          }
        } else {
          set(this.text, key, value)
        }
      });
    },

  },
  getters: {
    settingsElements: () => settingsElements,
  }
})