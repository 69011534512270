import { createRouter, createWebHistory } from 'vue-router'
import { routes } from './routes'

const injectFAQ = (faq) => {
  const script = document.createElement('script');
  script.type = "application/ld+json";
  script.innerHTML = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      ...faq.map((i) => ({
        "@type": "Question",
        "name": i.q,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": i.a
        }
      }))
    ]
  });
  document.getElementsByTagName('head')[0].appendChild(script);
};

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  strict: true,
  routes
})

router.beforeEach((to, from, next) => {

  const { name, query, meta, path } = to
  const { VUE_APP_CLIENT_URL: origin } = process.env
  const { ref } = query

  // Google drive authorization window
  if (name === 'authorized')
    window.close()

  // Set referral cookie
  if (ref)
    document.cookie = 'ref=' + ref + '; SameSite=None; Secure'

  // Add FAQ structured data
  if (meta.faq)
    injectFAQ(meta.faq)

  // Set page title
  if (meta.title)
    document.title = meta.title

  // Set description
  //console.log(router.$t('routes.' + to.name + '.pageDescription'))
  //document.querySelector('meta[name="description"]').setAttribute("content", router.$t('routes.' + to.name + '.pageDescription'))

  // Set canoncial tag
  document
    .querySelector('link[rel="canonical"]')
    .setAttribute('href', (origin ?? window.location.origin) + (meta.canoncial ?? path))

  // Re-route (if necessary)
  if (to.path.endsWith('/') && to.path.length > 1)
    next({ path: to.fullPath.slice(0, -1) })
  else if (to.fullPath.includes('/?'))
    next({ path: to.fullPath.replace('/?', '?') })

  next()
})

router.onAuth = (user) => {
  //document.querySelector('[name="theme-color"]').content = user ? 

  return new Promise(function (resolve) {
    if (!user && router.currentRoute.value.meta.requiresAuth) {
      router.push('/')
      setTimeout(() => resolve(), 1000)
    } else {
      resolve()
    }
  })
}

router.afterEach(() => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
})

export default router
