const constants = Object.freeze(
  {
    links: [
      {
        id: 1,
        icon: "FaBook",
        link: "/guider",
        title: "Textguider",
        default: true,
        external: false
      },
      {
        id: 2,
        icon: "HiSolidUsers",
        link: "/bjud-in",
        title: "Bjud in vänner",
        default: true,
        external: false
      },
      {
        id: 3,
        icon: "FaEnvelope",
        link: "/kontakta-oss",
        title: "Kontakta oss",
        default: true,
        external: false
      },
      {
        id: 4,
        icon: "GiScales",
        link: "/etik",
        title: "Etisk ställning",
        default: false,
        external: false
      },
      {
        id: 5,
        icon: "BiCalendar3",
        link: "https://schedulebuilder.org/sv",
        title: "Schemaläggare",
        default: true,
        external: true
      },
      {
        id: 6,
        icon: "FaPencilAlt",
        link: "/text",
        title: "Skriv text",
        service: true,
        external: false
      },
      {
        id: 7,
        icon: "fa-lightbulb-on",
        link: "/text",
        title: "Förbättra text",
        service: true,
        external: false
      },
      {
        id: 8,
        icon: "fa-check",
        link: "/text",
        title: "Hitta källor",
        service: true,
        external: false
      },
      {
        id: 9,
        icon: "GiCheckedShield",
        link: "/integritetspolicy",
        title: "Integritetspolicy",
        external: false
      },
      {
        id: 10,
        icon: "FaFileContract",
        link: "/villkor",
        title: "Användarvillkor",
        external: false
      }
    ],
    services: [
      {
        id: 1,
        icon: 'FaPencilAlt',
        image() {
          return require('../assets/skriv-text-full-small.webp')
        },
        imageSmall() {
          return require('../assets/skriv-text.webp')
        },
        link: '/text',
        title: 'Skriv text',
        body: 'Generera en unik och professionel text utifrån val av ämnesområde, skrivstil, språknivå, textlängd och andra parametrar. Tjänsten använder artificiell intelligens för att analysera din input och skapa en text som är anpassad efter dina behov.',
        count: 500,
        pricePerUnit: 0.002
      },
      {
        id: 2,
        icon: 'MdRotateleftRound',
        link: '/förbättra-text',
        image() {
          return require('../assets/förbättra-text-full-small.webp')
        },
        imageSmall() {
          return require('../assets/förbättra-text.webp')
        },
        title: 'Förbättra (kommer snart)',
        body: 'En AI-motor analyserar din text och ger förslag för hur den kan vidareutvecklas samt förbättras utifrån språk, stil och andra parametrar. Dessa förslag kan användas som grund för att skapa en ny version som passar dina ändamål bättre.',
        count: 500,
        pricePerUnit: 0.004,
        disabled: true
      },
      {
        id: 3,
        icon: 'SiOpenstreetmap',
        link: '/hitta-källor',
        image() {
          return require('../assets/hitta-källor-full-small.webp')
        },
        imageSmall() {
          return require('../assets/hitta-källor.webp')
        },
        title: 'Hitta källor (kommer snart)',
        body: 'Identifiera källor för ett visst påstående, en kort text eller utför en faktakontroll. Med artificiell intelligens och maskininlärning analyserar tjänsten innehållet i texten och jämför det med miljontals källor från hela världen för att hitta de mest relevanta.',
        count: 500,
        pricePerUnit: 0.003,
        disabled: true
      }
    ],
    guides: [
      {
        title: "Uppsats",
        description:
          "En uppsats är en strukturerad och argumenterande texttyp som kräver forskning, reflektion och stöd av en tydlig tes med bevis och källor. Den syftar till att övertyga och informera läsaren om författarens ståndpunkt genom att demonstrera förmågan att kritiskt och professionellt hantera ämnet, och genom att följa en fast struktur ger den en sammanhängande och övertygande presentation av ämnet.",
        shortDescription: "Uppsatser är strukturerade texter som argumenterar för teser med stöd från forskning och källor, syftande till att övertyga och informera läsarna på ett sammanhängande sätt.",
        metaTitle: 'Så skriver du en framgångsrik uppsats: steg-för-steg',
        imageAlt: 'Ung tjej sitter framför sitt skrivbord och läser i en anteckningsbok',
        image() {
          return require('../assets/uppsats_illustration_small.webp')
        },  
        icon() {
          return require('../assets/uppsats.webp')
        },
        faq: [
          {
            q: 'Vad är en uppsats?',
            a: 'En uppsats är en skriftlig akademisk text som syftar till att undersöka, analysera och presentera en specifik frågeställning, ett problem eller ett ämne. Den bygger på forskning och evidens och är vanligtvis ett krav inom utbildningssammanhang för att bedöma studenters förmåga att formulera och argumentera kring komplexa ämnen.'
          },
          {
            q: 'Hur lång bör en uppsats vara?',
            a: 'Längden på en uppsats varierar beroende på nivå, ämne och institutionens riktlinjer. Generellt sett kan en uppsats vara allt från några sidor lång (exempelvis 10-20 sidor) för mindre avancerade nivåer, till flera tiotals sidor (exempelvis 50-100 sidor eller mer) för avancerade akademiska nivåer som magister- eller doktorsavhandlingar.'
          },
          {
            q: 'Vilka ord ska man undvika i en akademisk text?',
            a: 'I en akademisk text bör du undvika att använda informella eller vardagliga uttryck, slang, förkortningar och överdrivet subjektiva påståenden. Du bör också undvika att använda första person (”jag”) om det inte är motiverat, samt att förlita dig på opålitliga källor eller okritiskt citera andra verk.'
          },
          {
            q: 'Hur formulerar man bra frågeställningar i en uppsats?',
            a: `För att formulera bra frågeställningar i en uppsats bör du:
            <ol class="mt-2">
              <li>Vara tydlig och konkret i dina formuleringar.</li>
              <li>Undvika att frågorna är för breda eller för snäva.</li>
              <li>Formulera frågorna så att de kan besvaras med den tillgängliga forskningen eller data.</li>
              <li>Använda klart definierade begrepp och termer.</li>
              <li>Uttrycka frågorna i en neutral och objektiv ton.</li>
            </ol>`
          },
          {
            q: 'Vad är syftet med att skriva en uppsats?',
            a: `För att formulera bra frågeställningar i en uppsats bör du:
            <ol class="mt-2">
              <li>Att utveckla och visa förmågan att bedriva forskning och kritiskt tänkande.</li>
              <li>Att fördjupa förståelsen för ett ämne eller problem genom studier och analys.</li>
              <li>Att bidra till befintlig kunskap genom att fylla i kunskapsluckor eller erbjuda nya insikter.</li>
              <li>Att lära sig att strukturera och presentera information på ett klart och övertygande sätt.</li>
              <li>Att förbättra skriv- och kommunikationsförmågor.</li>
            </ol>`
          },
          {
            q: 'Hur strukturerar man ett uppsats?',
            a: `För att formulera bra frågeställningar i en uppsats bör du:
            <ol class="mt-2">
              <li>Inledning: Bakgrund, problemformulering, syfte, avgränsningar, och struktur..</li>
              <li>Teoretisk ram och tidigare forskning: Teoretiska begrepp, tidigare forskning, kunskapsluckor, teoretiska kopplingar.</li>
              <li>Metod: Forskningsdesign, urval, datainsamlings- och analysmetoder, etiska överväganden, reliabilitet och validitet, procedurer och tidslinje.</li>
              <li>Resultat: Presentation och beskrivning av empiriska resultat.</li>
              <li>Diskussion och slutsatser: Tolkning av resultat, relation till teori och tidigare forskning, implikationer, svar på forskningsfrågor, begränsningar, framtidig forskning, slutsatser..</li>
            </ol>`
          },
        ]
      },
      {
        title: "Insändare",
        metaTitle: "Sätt ord på dina åsikter: Så formulerar du en klockren insändare",
        description:
          "Insändare är korta, opinionsbaserade texter som låter allmänheten uttrycka åsikter i aktuella ämnen eller samhällsfrågor i en personlig och passionerad ton, vilket ger enskilda individer en viktig röst och möjlighet att påverka debatten och uppmärksamheten kring olika frågor.",
        shortDescription: "Insändare är korta åsiktsartiklar som låter allmänheten uttrycka sina åsikter i aktuella ämnen och samhällsfrågor.",
        imageAlt: 'Person sitter vid ett bord och arbetar på sin insändare',
        image() {
          return require('../assets/insändare_illustration_small.webp')
        },  
        icon() {
          return require('../assets/insändare.webp')
        },
        faq: [
          {
            q: 'Hur skriver man en insändare?',
            a: 'För att skriva en insändare bör du först välja ett ämne av allmänt intresse. Sedan strukturerar du insändaren med en rubrik, inledning, huvudargument, motargument, avslutning och signatur. Var tydlig och koncis, använd engagerande element och ge stöd för dina åsikter med exempel, fakta eller statistik.'
          },
          {
            q: 'Vilka delar består en insändare av?',
            a: `En insändare består vanligtvis av följande delar: 
            <ol>
              <li>Rubrik</li>
              <li>Inledning och bakgrund</li>
              <li>Huvudargument</li>
              <li>Motargument och svar</li>
              <li>Avslutning</li>
              <li>Signatur</li>
            </ol>`
          },
          {
            q: 'Hur lång ska en insändare vara?',
            a: 'En insändare bör vara kort och koncis, vanligtvis inte längre än en A4-sida. Den bör vara tillräckligt lång för att presentera dina åsikter och argument på ett välgrundat sätt, men undvik att överbelasta texten med onödiga detaljer.'
          },
          {
            q: 'Får man ha egna åsikter i en insändare?',
            a: 'Ja, i en insändare förväntas det att du presenterar dina egna åsikter och ståndpunkter om det aktuella ämnet. Insändare är en plattform för att uttrycka personliga åsikter och delta i den offentliga debatten.'
          },
          {
            q: 'Vad är syftet med en insändare?',
            a: 'Syftet med en insändare är att uttrycka åsikter, tankar eller synpunkter om ämnen av allmänt intresse. Den ger en möjlighet för individer eller grupper att delta i den offentliga debatten, bidra till yttrandefriheten och främja en öppen och demokratisk dialog om olika frågor.'
          },
          {
            q: 'Hur ska argumenten formuleras i en insändare?',
            a: 'Argumenten i en insändare bör vara tydliga, starka och välunderbyggda. Använd fakta, bevis och logik för att stödja dina åsikter. Bemöt även eventuella motargument på ett konstruktivt sätt för att visa en balanserad diskussion.'
          },
          {
            q: 'Vad är skillnaden mellan en krönika och en insändare?',
            a: 'Skillnaden mellan en krönika och en insändare ligger i deras syften och stil. En insändare syftar till att uttrycka åsikter om allmänt intressanta ämnen och följer en tydlig struktur med rubrik, inledning, huvudargument, etc. En krönika å andra sidan är en personlig text där författaren ofta använder sig av en mer avslappnad och litterär stil för att dela personliga reflektioner, erfarenheter eller betraktelser. Krönikor är vanligtvis inte strukturerade med en tydlig rubrik eller avdelningar som en insändare.'
          }
        ]
      },
      {
        title: "Krönika",
        description:
          "Krönikor är personliga texter som ger läsaren en inblick i författarens reflektioner och åsikter om historiska eller samtida händelser, genom att kombinera fakta med egna tolkningar och betraktelser. De erbjuder en unik och levande skildring av aktuella händelser eller tidigare epoker.",
        shortDescription: "Krönikor är personliga texter som kombinerar fakta med författarens reflektioner och åsikter om historiska eller samtida händelser.",
        metaTitle: 'Så skriver du en engagerande krönika – Steg för steg',
        imageAlt: 'Personer sitter vid köksbord och författar en krönika',
        image() {
          return require('../assets/krönika_illustration_small.webp')
        },  
        icon() {
          return require('../assets/krönika.webp')
        },
        faq: [
          {
            q: 'Hur skriver man en krönika?',
            a: 'En krönika skrivs genom att först välja ett ämne eller en händelse som intresserar författaren och som denne vill uttrycka sina åsikter, tankar och reflektioner om. Sedan använder författaren en personlig och subjektiv ton för att analysera ämnet och presentera sina åsikter. Det är viktigt att inkludera exempel och illustrationer för att stödja argumenten och göra texten engagerande för läsarna.'
          },
          {
            q: 'Hur kan en krönika struktureras?',
            a: 'En krönika kan struktureras på olika sätt beroende på författarens stil och ämnet som behandlas. En typisk struktur omfattar en inledning där ämnet introduceras, en analysdel där författaren utforskar ämnet och presenterar sina åsikter, exempel och illustrationer för att stödja argumenten, en bemötande del för motargument och replik samt en avslutning där huvudpoängen sammanfattas och författarens avslutande reflektioner presenteras.'
          },
          {
            q: 'Hur lång bör en krönika vara?',
            a: 'Längden på en krönika varierar, men vanligtvis ligger de mellan 500 och 1000 ord. Den exakta längden kan dock bero på publiceringsplatsens riktlinjer och krav.'
          },
          {
            q: 'Vad är typiskt för en krönika?',
            a: 'En krönika är en personlig, subjektiv och reflekterande åsiktstext där författaren, krönikören, uttrycker sina åsikter och tankar om olika ämnen eller händelser. Krönikor har ofta en regelbunden plats i publikationen och används för att analysera och diskutera aktuella frågor på ett mer personligt och engagerande sätt.'
          },
          {
            q: 'Hur blir man krönikör?',
            a: 'För att bli krönikör behöver man utveckla sin förmåga att skriva engagerande och personliga texter. Det kan vara till hjälp att ha en stark röst och åsikt om olika ämnen samt att vara insatt i samhällsfrågor och aktuella händelser. Att öva skrivande genom bloggar eller egna texter kan hjälpa till att förbättra skrivfärdigheterna. Sedan kan man försöka bli publicerad i tidningar, tidskrifter eller på webbplatser som krönikör.'
          },
          {
            q: 'Vad är skillnaden mellan krönika och debattartikel?',
            a: 'En krönika och en debattartikel har likheter, men det finns några viktiga skillnader. En krönika är en subjektiv och personlig åsiktstext där författaren reflekterar och analyserar olika ämnen ur sitt eget perspektiv. Åsikterna är mer löst förankrade och baserade på författarens upplevelser och tankar.<br><br>Å andra sidan är en debattartikel en mer formell och objektiv text där författaren presenterar starka argument och bevis för en särskild ståndpunkt om ett ämne. Debattartiklar är oftast mer forskningsbaserade och syftar till att övertyga läsarna om en viss tes eller hållning.'
          },
          {
            q: 'Vad är skillnad på insändare och krönika?',
            a: 'En insändare och en krönika är båda typer av journalistiska texter, men de skiljer sig i syfte och stil. En insändare är en kortare text där en person uttrycker sina åsikter eller synpunkter om ett ämne till en tidning eller tidskrift. Insändare är oftast mer reaktiva och behandlar aktuella händelser eller frågor.<br><br>Å andra sidan är en krönika en längre och mer reflekterande åsiktstext där författaren har mer utrymme att analysera ämnet och presentera sina tankar och erfarenheter. Krönikor är vanligtvis mer personliga och har ofta en regelbunden plats i publikationen.'
          },
        ]
      },
      {
        title: "Debattartikel",
        metaTitle: "Skriv en vinnande debattartikel: Tips för att nå ut och påverka",
        description:
          "En debattartikel är en texttyp som presenterar en välgrundad åsikt eller argumentation om en specifik fråga, där författaren använder tydliga argument, bevis och exempel för att övertyga läsarna om sitt perspektiv. Dessa artiklar publiceras vanligtvis i tidningar, tidskrifter eller online-plattformar och bidrar till den offentliga debatten genom att främja förståelse för komplexa frågor genom att presentera och diskutera olika åsikter.",
        shortDescription: "Debattartiklar presenterar välgrundade åsikter och argument i specifika frågor för att övertyga läsarna om författarnas perspektiv.",
        imageAlt: 'Illustration av två personer som deltar i en vänskaplig debatt',
        image() {
          return require('../assets/debatt_illustration_small.webp')
        },  
        icon() {
          return require('../assets/debattartikel.webp')
        },
        faq: [
          {
            q: 'Hur skriver man en debattartikel?',
            a: 'För att skriva en debattartikel behöver du välja ett ämne du känner starkt för eller har god kännedom om. Börja med en engagerande inledning som presenterar ämnet och din tes. Utveckla sedan din argumentation i huvuddelen med tydliga och välgrundade argument. Bemöt potentiella motargument i en separat del och avsluta med en stark sammanfattning som förstärker din ståndpunkt och kanske uppmanar till handling eller reflektion.'
          },
          {
            q: 'Hur strukturerar man en debattartikel?',
            a: 'En vanlig struktur för en debattartikel består av följande delar: 1) Inledning med ämnet och tesen 2)  Argumentation med olika välgrundade argument, 4) Motargument där du bemöter potentiella invändningar, 5) Avslutning som sammanfattar din ståndpunkt och eventuellt uppmanar till handling eller reflektion.'
          },
          {
            q: 'Hur lång är en debattartikel?',
            a: 'En debattartikel varierar i längd beroende på publikationen eller forumet där den publiceras. Generellt sett är den vanligtvis några hundra ord lång, mellan 500 och 1000 ord. Kortare artiklar på 300-500 ord kan också vara vanliga beroende på sammanhanget.'
          },
          {
            q: 'Vad innebär tes och argument i en debattartikel?',
            a: 'I en debattartikel är tesen den centrala påståendet eller huvudidén som författaren vill framföra och argumentera för. Den representerar författarens ståndpunkt om det specifika ämnet. Argumenten är de olika punkter och fakta som används för att stödja tesen. De fungerar som bevis för att övertyga läsaren om att tesen är giltig och trovärdig.'
          },
          {
            q: 'Vad är skillnaden på debattartikel och debattinlägg?',
            a: 'Skillnaden mellan en debattartikel och ett debattinlägg ligger främst i längd och form. En debattartikel är vanligtvis längre och har en mer strukturerad form, med en inledning, tes, argument, motargument och avslutning. Den publiceras ofta i tidningar, tidskrifter eller på webbplatser. Å andra sidan är ett debattinlägg kortare och kan vara mer informellt. Det publiceras oftare på webbplatser, bloggar eller sociala medier.'
          },
          {
            q: 'Vad är syftet med en debattartikel?',
            a: 'Syftet med en debattartikel är att presentera en välgrundad åsikt eller argumentation om ett specifikt ämne. Den syftar till att övertyga och informera läsarna om författarens ståndpunkt och stimulera en bredare förståelse för komplexa frågor genom logiska och välinformerade argument.'
          },
          {
            q: 'Vilka aspekter bör beaktas vid formuleringen av en tes i en debattartikel?',
            a: 'Vid formuleringen av en tes i en debattartikel bör följande aspekter beaktas: tydlighet, precision, kontroversialitet, förankring i bevis och stark formulering. Tesen bör vara tydligt och klart uttryckt, specifik och välavgränsad, utmanande och intressant, välgrundad med fakta och stödd av relevanta källor samt formulerad på ett starkt och övertygande sätt.'
          },
          {
            q: 'Vad är syftet med motargumentsdelen i en debattartikel?',
            a: 'Syftet med motargumentsdelen i en debattartikel är att bemöta potentiella motargument som kan riktas mot författarens ståndpunkt eller tes. Genom att adressera olika perspektiv och bemöta motargument på ett respektfullt och sakligt sätt visar författaren på styrkan i sin egen ståndpunkt och förmågan att hantera komplexa frågor.'
          },
          {
            q: 'Vad är syftet med avslutningen i en debattartikel?',
            a: 'Syftet med avslutningen i en debattartikel är att sammanfatta och förstärka författarens ståndpunkt samt ge ett avslutande intryck på läsarna. Avslutningen ska vara kraftfull och övertygande och anknyta till tesen och argumenten som tidigare presenterats i artikeln. Det är också en möjlighet för författaren att uppmana till handling eller reflektion och belysa ämnets betydelse för framtiden.'
          },
        ]
      },
      {
        title: "Nyhetsartikel",
        description:
          "Nyhetsartiklar är objektiva och informativa texter som rapporterar om aktuella händelser och fakta genom en strukturerad stil med viktiga detaljer presenterade först, vilket fungerar som ett viktigt medium för att hålla allmänheten uppdaterad om vad som händer i samhället och världen.",
        shortDescription: "Nyhetsartiklar är objektiva och informativa rapporter om aktuella händelser och fakta.",
        metaTitle: 'Från rubrik till slutkläm – Så skriver du en effektiv nyhetsartikel',
        imageAlt: 'Ung kille står framför ett tidningstånd och håller i en av tidningarna',
        image() {
          return require('../assets/nyhetsartikel_illustration_small.webp')
        },  
        icon() {
          return require('../assets/nyhetsartikel.webp')
        },
        faq: [
          {
            q: 'Hur skriver man en debattartikel?',
            a: 'För att skriva en debattartikel behöver du välja ett ämne du känner starkt för eller har god kännedom om. Börja med en engagerande inledning som presenterar ämnet och din tes. Utveckla sedan din argumentation i huvuddelen med tydliga och välgrundade argument. Bemöt potentiella motargument i en separat del och avsluta med en stark sammanfattning som förstärker din ståndpunkt och kanske uppmanar till handling eller reflektion.'
          },
          {
            q: 'Hur strukturerar man en debattartikel?',
            a: 'En vanlig struktur för en debattartikel består av följande delar: 1) Inledning med ämnet och tesen 2)  Argumentation med olika välgrundade argument, 4) Motargument där du bemöter potentiella invändningar, 5) Avslutning som sammanfattar din ståndpunkt och eventuellt uppmanar till handling eller reflektion.'
          },
          {
            q: 'Hur lång är en debattartikel?',
            a: 'En debattartikel varierar i längd beroende på publikationen eller forumet där den publiceras. Generellt sett är den vanligtvis några hundra ord lång, mellan 500 och 1000 ord. Kortare artiklar på 300-500 ord kan också vara vanliga beroende på sammanhanget.'
          },
          {
            q: 'Vad innebär tes och argument i en debattartikel?',
            a: 'I en debattartikel är tesen den centrala påståendet eller huvudidén som författaren vill framföra och argumentera för. Den representerar författarens ståndpunkt om det specifika ämnet. Argumenten är de olika punkter och fakta som används för att stödja tesen. De fungerar som bevis för att övertyga läsaren om att tesen är giltig och trovärdig.'
          },
          {
            q: 'Vad är skillnaden på debattartikel och debattinlägg?',
            a: 'Skillnaden mellan en debattartikel och ett debattinlägg ligger främst i längd och form. En debattartikel är vanligtvis längre och har en mer strukturerad form, med en inledning, tes, argument, motargument och avslutning. Den publiceras ofta i tidningar, tidskrifter eller på webbplatser. Å andra sidan är ett debattinlägg kortare och kan vara mer informellt. Det publiceras oftare på webbplatser, bloggar eller sociala medier.'
          },
          {
            q: 'Vad är syftet med en debattartikel?',
            a: 'Syftet med en debattartikel är att presentera en välgrundad åsikt eller argumentation om ett specifikt ämne. Den syftar till att övertyga och informera läsarna om författarens ståndpunkt och stimulera en bredare förståelse för komplexa frågor genom logiska och välinformerade argument.'
          },
          {
            q: 'Vilka aspekter bör beaktas vid formuleringen av en tes i en debattartikel?',
            a: 'Vid formuleringen av en tes i en debattartikel bör följande aspekter beaktas: tydlighet, precision, kontroversialitet, förankring i bevis och stark formulering. Tesen bör vara tydligt och klart uttryckt, specifik och välavgränsad, utmanande och intressant, välgrundad med fakta och stödd av relevanta källor samt formulerad på ett starkt och övertygande sätt.'
          },
          {
            q: 'Vad är syftet med motargumentsdelen i en debattartikel?',
            a: 'Syftet med motargumentsdelen i en debattartikel är att bemöta potentiella motargument som kan riktas mot författarens ståndpunkt eller tes. Genom att adressera olika perspektiv och bemöta motargument på ett respektfullt och sakligt sätt visar författaren på styrkan i sin egen ståndpunkt och förmågan att hantera komplexa frågor.'
          },
          {
            q: 'Vad är syftet med avslutningen i en debattartikel?',
            a: 'Syftet med avslutningen i en debattartikel är att sammanfatta och förstärka författarens ståndpunkt samt ge ett avslutande intryck på läsarna. Avslutningen ska vara kraftfull och övertygande och anknyta till tesen och argumenten som tidigare presenterats i artikeln. Det är också en möjlighet för författaren att uppmana till handling eller reflektion och belysa ämnets betydelse för framtiden.'
          },
        ]
      },
      {
        title: "Reportage",
        description:
          "Reportage är detaljerade och objektiva texter som syftar till att ge en fördjupad förståelse av ett ämne genom omfattande forskning, intervjuer och observationer. Genom levande beskrivningar och personliga berättelser ger reportage läsaren en nära upplevelse av ämnet och belyser olika aspekter för att ge en heltäckande bild av det som undersöks.",
        shortDescription: "Reportage är detaljerade och objektiva texter som ger en fördjupad förståelse genom omfattande forskning, intervjuer och observationer.",
        metaTitle: 'Skapa Engagerande Reportage: Tips och Trix',
        imageAlt: 'Illustration av en kvinnlig journalist som arbetar med ett reportage',
        image() {
          return require('../assets/reportage_illustration_small.webp')
        },  
        icon() {
          return require('../assets/reportage.webp')
        },
        faq: [
          {
            q: 'Hur skriver man ett reportage?',
            a: 'Att skriva ett reportage innebär att rapportera om händelser, fenomen eller människor genom att kombinera fakta med personliga iakttagelser och beskrivningar. För att skriva ett reportage bör du välja ett intressant ämne, samla relevanta fakta och information, genomföra intervjuer om det behövs, och sedan använda dessa material för att konstruera en berättelse som ger läsaren en djupare förståelse av ämnet. Det är viktigt att använda en kreativ och lockande rubrik, en fångande ingress, en välstrukturerad brödtext med fakta, '
          },
          {
            q: 'Hur inleder man ett reportage?',
            a: 'Ett reportage inleds vanligtvis med en kort ingress som fångar läsarens uppmärksamhet och ger en övergripande bild av vad reportaget kommer att handla om. Ingressen besvarar ofta de grundläggande frågorna: vem, vad, var, när och varför. Den ska vara lockande och få läsaren att vilja fortsätta läsa. Ett effektivt sätt att inleda ett reportage är att använda en intressant anekdot, en retorisk fråga eller ett provocerande påstående.'
          },
          {
            q: 'Hur strukturerar man ett reportage?',
            a: `Ett reportage struktureras vanligtvis på följande sätt:
            <ol class="mt-2">
              <li>Rubrik: En kreativ och lockande rubrik som sammanfattar ämnet och väcker läsarens intresse.</li>
              <li>Ingress: En kort inledning som fångar läsarens uppmärksamhet och ger en övergripande bild av vad reportaget kommer att handla om.</li>
              <li>Inledning: Presenterar ämnet eller händelsen som reportaget kommer att behandla och ger en grundläggande förståelse.</li>
              <li>Brödtext: Utforskar ämnet i detalj genom beskrivningar, citat, berättelser och analyser.</li>
              <li>Avslutning: Sammanfattar och avrundar ämnet, ger en slutsats eller reflektion, och kan inkludera en framåtblick mot framtida utvecklingar.</li>
            </ol>`
          },
          {
            q: 'Vad är syftet med ett repportage?',
            a: 'Syftet med ett reportage är att rapportera om och fördjupa förståelsen av ett specifikt ämne, händelse eller fenomen. Det kombinerar fakta med personliga iakttagelser och beskrivningar för att ge läsaren en rikare och mer nyanserad bild av ämnet. Genom att använda olika journalistiska tekniker, som intervjuer, beskrivningar och analyser, syftar reportaget till att engagera och informera läsaren på ett djupare sätt än en vanlig nyhetsartikel.'
          },
          {
            q: 'Kan man använda jag-form i reportage?',
            a: 'Ja, det är möjligt att använda jag-form i ett reportage. Att använda jag-formen innebär att journalisten inkluderar sina personliga iakttagelser, reflektioner och erfarenheter i texten. Det kan hjälpa till att skapa en mer nära och engagerande berättelse, särskilt när det handlar om ämnen där journalistens närvaro och upplevelse är relevant.'
          },
          {
            q: 'Vad är skillnaden på reportage och nyhetsartikel?',
            a: 'Skillnaden mellan ett reportage och en nyhetsartikel ligger främst i deras syften och stilar:<br><br>Reportage: Syftar till att fördjupa förståelsen av ett ämne genom att kombinera fakta med personliga iakttagelser och beskrivningar. Det är mer utforskande och berättande, och det strävar efter att engagera och involvera läsaren känslomässigt.<br><br>Nyhetsartikel: Syftar till att snabbt och objektivt rapportera om aktuella händelser eller fakta. Nyhetsartiklar är kortfattade och inriktade på att informera läsaren om det senaste, med fokus på vem, vad, var, när och varför. De är oftast skrivna i en neutral och objektiv ton.'
          },
        ]
      },
      {
        title: "Novell",
        description:
          "Noveller är kortprosa som effektivt utvecklar karaktärer, handling och stämning för att ge läsaren en snabb och intensiv läsupplevelse, ofta med en vändpunkt eller överraskande slut som lämnar intryck. Denna populära texttyp har en koncis och skicklig berättarkonst som fortsätter att inspirera och underhålla läsare genom historien.",
        shortDescription: "Noveller är korta berättelser som fångar läsaren med sina intensiva vändningar och överraskande slut.",
        metaTitle: 'Så skriver du en novell som trollbinder läsarna: En steg-för-steg-guide',
        imageAlt: 'Illustration av en gamal bok i förgrunden med en varm magisk värld i bakgrunden',
        image() {
          return require('../assets/novell_illustration_small.webp')
        },  
        icon() {
          return require('../assets/novell.webp')
        },
        faq: [
          {
            q: 'Hur skriver man en novell?',
            a: 'Börja med att utveckla en intressant idé eller konflikt som du vill utforska i berättelsen. Skapa en inledning som introducerar karaktärerna, miljön och konflikten. Utveckla sedan den stigande handlingen genom att presentera olika hinder och utmaningar för huvudkaraktären. När spänningen når sin höjdpunkt, skriv klimaxet där huvudkonflikten når sin avgörande punkt. Avsluta sedan med den fallande handlingen, där du löser konflikten och ger läsarna en upplösning och förståelse för huvudkaraktärens öde. Glöm inte att använda språket för att skapa atmosfär, karaktärsutveckling och engagera läsaren.'
          },
          {
            q: 'Hur är en novell uppbyggd?',
            a: `En novell är uppbyggd kring en koncisa berättelse som ofta fokuserar på ett enskilt händelseförlopp, ett problem eller en känslomässig upplevelse. Dess struktur består vanligtvis av följande element: 
            <ol class="mt-2">
              <li>Inledning: Introduktionen av karaktärer, miljö och den grundläggande konflikten.</li>
              <li>Stigande handling: Konflikten utvecklas, och spänningen ökar när huvudkaraktären möter hinder och utmaningar.</li>
              <li>Klimax: Den mest intensiva fasen där konflikten når sin höjdpunkt.</li>
              <li>Fallande handling: Spänningen avtar, och huvudkaraktären tar itu med de sista utmaningarna.</li>
              <li>Avslutning: Konflikten löses på ett eller annat sätt, och en förändring sker. Novellens tema eller budskap framträder oftast tydligare här.</li>
            </ol>`
          },
          {
            q: 'Hur lång ska en novell vara?',
            a: 'En novell är generellt sett kortare än en roman men längre än en kort berättelse. Den brukar vanligtvis ha mellan 5 000 och 20 000 ord, men det finns ingen fast gräns för novellens längd. En novell kan vara så kort som några sidor eller upp till flera kapitel. Det viktiga är att den har en tydlig och välutvecklad berättelse med en inledning, stigande handling, klimax, fallande handling och avslutning.'
          },
          {
            q: 'Vad är syftet med en novell?',
            a: 'Syftet med en novell är att berätta en kort och koncis berättelse som fokuserar på ett enskilt händelseförlopp, ett problem eller en känslomässig upplevelse. Novellen strävar ofta efter att ge läsaren en tankeställare, en känslomässig upplevelse eller en överraskande avslutning. Novellens korta format ger författaren möjlighet att snabbt skapa en atmosfär och dra läsaren in i handlingen. Den kan användas för att utforska komplexa teman och karaktärsutveckling på ett koncist sätt.'
          },
          {
            q: 'Vad är en vändpunkt i en novell?',
            a: 'En vändpunkt i en novell är en oväntad händelse, en upptäckt eller en dramatisk förändring som förändrar riktningen för berättelsen och tvingar huvudkaraktären att omvärdera sina handlingar eller strategier. Vändpunkter skapar spänning och intresse hos läsaren genom att bryta mönstret och presentera nya utmaningar eller möjligheter för huvudkaraktären. Vändpunkter kan vara avgörande för hur berättelsen utvecklas och kan leda till en ny fas av konflikten eller till en lösning.'
          },
          {
            q: 'Vad är skillnaden på en novell och en roman?',
            a: 'Skillnaden mellan en novell och en roman ligger huvudsakligen i längd och komplexitet. En novell är kortare och har vanligtvis mellan 5 000 och 20 000 ord, medan en roman är betydligt längre, ofta mer än 50 000 ord. Noveller har en mer koncis och fokuserad berättelse, medan romaner ger utrymme för mer djupgående karaktärsutveckling, subplots och en omfattande undersökning av teman. Noveller strävar ofta efter att ge en snabb och kraftfull upplevelse, medan romaner ger möjlighet till en mer utforskande och fördjupad läsupplevelse.'
          }
        ]
      },
      {
        title: "Referat",
        description:
          "Referat är korta och sammanfattande texter som ger en översikt av huvudpoängen och viktig information från längre texter eller presentationer. De hjälper läsaren att snabbt förstå innehållet och dra slutsatser utan att behöva läsa den fullständiga texten och används ofta inom akademiska, professionella och forskningsområden för att dela resultat och kunskap på ett överskådligt sätt.",
        shortDescription: "Referat är korta och sammanfattande texter som ger en översikt av huvudpoängen och viktig information från längre texter eller presentationer.",
        metaTitle: 'Sammanfatta med stil: Så skapar du intressanta och välstrukturerade referat',
        imageAlt: 'En äldre herre sitter i en biosalong och tar anteckningar till en film',
        image() {
          return require('../assets/referat_illustration_small.webp')
        },  
        icon() {
          return require('../assets/referat.webp')
        },
        faq: [
          {
            q: 'Hur skriver man ett referat?',
            a: 'Ett referat skrivs genom att sammanfatta det ursprungliga materialet på ett kortfattat och koncist sätt. För att göra detta bör du följa en strukturerad metod som inkluderar inledning, sammanfattning och avslutning. Läs igenom det ursprungliga materialet noggrant och identifiera de viktigaste punkterna och huvudidéerna. Använd sedan dina egna ord för att återge dessa i referatet.'
          },
          {
            q: 'Hur inleder man ett referat?',
            a: 'I inledningen av ett referat ger du en introduktion till det ursprungliga materialet. Du bör inkludera titeln på det ursprungliga materialet, namnet på författaren/författarna, publikationen eller källan där originalet finns, samt publiceringsdatumet. Dessutom kan du ge en kort beskrivning av syftet med det ursprungliga materialet och ge lite bakgrundsinformation om ämnet eller ämnesområdet för att sätta referatet i ett större sammanhang.'
          },
          {
            q: 'Vad ska man ha med i ett referat?',
            a: `Följande delar bör ingå i ett referat:
            <ol class="mt-2">
              <li>Inledning: Introduktion till det ursprungliga materialet med titel, författare, publikation och syfte.</li>
              <li>Sammanfattning: Kortfattad återgivning av de viktigaste punkterna och huvudidéerna i det ursprungliga materialet, uttryckt med egna ord.</li>
              <li>Avslutning: Sammanfattning av det huvudsakliga innehållet och eventuella slutsatser från det ursprungliga materialet.</li>
            </ol>`
          },
          {
            q: 'Vad är syftet med att referera?',
            a: 'Syftet med att referera är att ge läsaren en översikt av det ursprungliga materialet utan att denne behöver läsa hela originaltexten. Referat är användbara när man vill snabbt få en förståelse för vad en text handlar om, utan att gå in i detaljerna. Det används ofta i akademiska sammanhang för att sammanfatta forskningsartiklar och vetenskapliga texter.'
          },
          {
            q: 'Hur använder man referatmarkörer?',
            a: `Referatmarkörer, även kända som signalfraser, är uttryck som används för att introducera eller signalera att en åsikt, åsikt, fakta eller information kommer att refereras eller återberättas i ett skriftligt arbete. Dessa markörer hjälper läsaren att förstå att följande information kommer från en annan källa eller att författaren refererar till någon annans tankar eller idéer. Exempel på referatmarkörer:
            <ol class="mt-2">
              <li>Enligt X...</li>
              <li>I en studie av Y...</li>
              <li>Författare Z hävdar att...</li>
              <li>Deras forskning visar att..</li>
              <li>I sin slutsats nämner hen..</li>
            </ol>`
          },
          {
            q: 'Hur långt bör ett referat vara?',
            a: 'Längden på ett referat kan variera beroende på det ursprungliga materialets längd och komplexitet. Generellt sett bör ett referat vara tillräckligt kort för att ge en översikt, men tillräckligt långt för att inkludera de viktigaste punkterna och huvudidéerna. Ett referat kan vanligtvis vara några meningar till en kortare text på ett par stycken, beroende på originaltextens omfattning.'
          },
          {
            q: 'Hur anger man källor i referat?',
            a: 'I ett referat bör du ange källorna för det ursprungliga materialet så att läsaren kan hänvisa till den fullständiga texten om de vill ha mer detaljer. Använd gärna in-textcitat (APA, MLA, eller annan stil som följs) för att korrekt hänvisa till originalkällan i din sammanfattning. Till exempel, om du refererar till en forskningsartikel, inkludera författarens namn och publiceringsår i parentes efter citatet eller den information du har tagit från originalet. Exakt hur du anger källor beror på vilken referensstil som används (t.ex. APA, MLA, Chicago) och vilka riktlinjer som är aktuella för ditt specifika sammanhang.'
          },
        ]
      },
    ],
    customParamNames: {
      bullet: 'Rubrik',
      bullets: 'Rubriker',
      keywords: 'Nyckelord',
      text: 'Text'
    },
    defaultText: {
      stepId: 0,
      maxStepId: 0,
      title: '',
      description: '',
      type: 'Start',
      language: '',
      structure: [],
      tokens: {},
      credits: 0,
      docOptions: []
    },
    languages: [
      "Engelska",
      "Svenska",
      "afariska",
      "abchaziska",
      "avestiska",
      "afrikaans",
      "akan",
      "amhariska",
      "aragonesiska",
      "arabiska",
      "assamesiska",
      "avariska",
      "aymara",
      "azerbajdzjanska",
      "basjkiriska",
      "vitryska",
      "bulgariska",
      "bihari-språk",
      "bislama",
      "bambara",
      "bengali",
      "tibetanska",
      "bretonska",
      "bosniska",
      "katalanska",
      "tjetjenska",
      "chamorro",
      "korsikanska",
      "cree",
      "tjeckiska",
      "kyrkslaviska; gammalbulgariska; gammelkyrkslaviska",
      "tjuvasjiska",
      "walesiska",
      "danska",
      "tyska",
      "divehi; dhivehi; maldiviska",
      "dzongkha",
      "ewe",
      "grekiska, modern",
      "esperanto",
      "spanska",
      "estniska",
      "baskiska",
      "persiska",
      "fula",
      "finska",
      "fijianska",
      "färöiska",
      "franska",
      "västfrisiska",
      "iriska",
      "gäliska; skotsk gäliska",
      "galiciska",
      "guaraní",
      "gujarati",
      "manx",
      "hausa",
      "hebreiska",
      "hindi",
      "hiri motu",
      "kroatiska",
      "haitiska; haitisk kreol",
      "ungerska",
      "armeniska",
      "herero",
      "interlingua (Internationella hjälpspråksförbundet)",
      "indonesiska",
      "interlingue; occidental",
      "igbo",
      "sichuan-yi; nuosu",
      "inupiak",
      "ido",
      "isländska",
      "italienska",
      "inuktitut",
      "japanska",
      "javanesiska",
      "georgiska",
      "kongo",
      "kikuyu; gikuyu",
      "kuanyama; kwanyama",
      "kazakiska",
      "grönländska",
      "central khmer",
      "kannada",
      "koreanska",
      "kanuri",
      "kashmiriska",
      "kurdiska",
      "komi",
      "korniska",
      "kirgiziska",
      "latin",
      "luxemburgiska; letzeburgiska",
      "ganda",
      "limburgiska",
      "lingala",
      "laotiska",
      "litauiska",
      "luba-katanga",
      "lettiska",
      "madagaskiska",
      "marshallesiska",
      "maori",
      "makedonska",
      "malayalam",
      "mongoliska",
      "marathi",
      "malajiska",
      "maltesiska",
      "burmesiska",
      "nauruanska",
      "bokmål, norska; norska (bokmål)",
      "ndebele, nord; nordndebele",
      "nepalesiska",
      "ndonga",
      "nederländska; flamländska",
      "nynorska; nynorska, norska",
      "norska",
      "ndebele, syd; sydndebele",
      "navajo; navaho",
      "chichewa; chewa; nyanja",
      "occitanska (efter 1500)",
      "ojibwa",
      "oromo",
      "odia",
      "ossetiska",
      "panjabi; punjabi",
      "pali",
      "polska",
      "pashto; pushto",
      "portugisiska",
      "quechua",
      "rätoromanska",
      "rundi",
      "rumänska; moldaviska",
      "ryska",
      "kinjarwanda",
      "sanskrit",
      "sardiska",
      "sindhi",
      "nordsamiska",
      "sango",
      "singalesiska",
      "slovakiska",
      "slovenska",
      "samoanska",
      "shona",
      "somaliska",
      "albanska",
      "serbiska",
      "swati",
      "sydsotho",
      "sundanesiska",
      "swahili",
      "tamil",
      "telugu",
      "tadzjikiska",
      "thailändska",
      "tigrinja",
      "turkmeniska",
      "tagalog",
      "tswana",
      "tonga (Tongaöarna)",
      "turkiska",
      "tsonga",
      "tatariska",
      "twi",
      "tahitiska",
      "uiguriska; uygur",
      "ukrainska",
      "urdu",
      "uzbekiska",
      "venda",
      "vietnamesiska",
      "volapük",
      "vallonska",
      "wolof",
      "xhosa",
      "jiddisch",
      "yoruba",
      "zhuang; chuang",
      "kinesiska",
      "zulu"
    ],
  }
)

module.exports = constants